import React from 'react'

export default function CSImg() {
  return (
	<svg viewBox="0 0 29.8 25" fill="currentColor">
		<path d="M2.2,20.6c0,0,0.1,0.1,0.1,0.1c0.5-0.1,0.9-0.2,1.4-0.3c1.6-0.5,3.2-1.3,4.5-2.3L8.4,18h0.1
			c1,0.2,1.9,0.3,2.9,0.3l0,0c2,0,4-0.4,5.8-1.3c1.7-0.7,3.1-1.9,4.2-3.4c2-2.7,2-6.4,0-9.1c-1.1-1.5-2.5-2.6-4.2-3.4
			C15.4,0.4,13.4,0,11.4,0c-2,0-4,0.4-5.8,1.2C4,2,2.6,3.1,1.5,4.6C0.5,5.9,0,7.5,0,9.1c0,1.4,0.4,2.8,1.1,4c0.8,1.3,1.9,2.4,3.2,3.2
			l0.2,0.2l-0.1,0.3c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.3,0.5-0.5,0.8l-0.4,0.5c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.3,0.4-0.4,0.4
			l-0.1,0l0,0l-0.1,0.1l-0.1,0.1v0.1C2.2,20.5,2.2,20.5,2.2,20.6z M2.4,12.3c-0.6-0.9-0.9-2-0.9-3.1c0-1.4,0.5-2.7,1.4-3.8
			c1-1.2,2.2-2.1,3.7-2.7c3.2-1.3,6.7-1.3,9.9,0C17.8,3.2,19,4.1,20,5.3c0.9,1.1,1.4,2.4,1.4,3.8c0,1.4-0.5,2.7-1.4,3.8
			c-1,1.2-2.2,2.1-3.7,2.7c-2.4,1-5,1.3-7.6,0.7L8,16.2l-0.6,0.5c-0.3,0.2-0.7,0.5-1.1,0.7l-0.9,0.6l0.9-2.3L5,14.9
			C4,14.2,3.1,13.3,2.4,12.3L2.4,12.3z M28.7,17.7c1.6-2.4,1.6-5.6-0.1-8c-0.9-1.3-2-2.4-3.4-3.1c0.3,0.9,0.4,1.8,0.4,2.7
			c0,1.6-0.4,3.1-1.1,4.5c-0.8,1.5-1.9,2.7-3.2,3.7c-1.3,1-2.8,1.8-4.4,2.3c-1.7,0.5-3.4,0.8-5.1,0.8c-0.3,0-0.8,0-1.5-0.1
			c2.3,1.6,5,2.4,7.8,2.3c1,0,2-0.1,2.9-0.3c1.4,1,3,1.8,4.6,2.2c0.5,0.1,0.9,0.2,1.4,0.3c0.1,0,0.3,0,0.4-0.1
			c0.1-0.1,0.2-0.2,0.2-0.4c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1v-0.1V24l-0.1,0l-0.1-0.1l-0.1-0.1l-0.1-0.1c0-0.1-0.2-0.2-0.4-0.4
			s-0.3-0.4-0.4-0.5s-0.2-0.3-0.4-0.5c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.3-0.2-0.5-0.3-0.8C26.8,20,27.9,19,28.7,17.7z M44.4,19h-2
			c0-0.3-0.1-0.6-0.3-0.9c-0.5-0.7-1-1.4-1.5-2.1c-0.6-0.8-1-1.4-1.2-1.7c-0.2,0.3-0.6,0.9-1.2,1.7c-0.5,0.7-1.1,1.4-1.5,2.2
			c-0.2,0.3-0.3,0.6-0.3,0.9h-2V9.9c0-0.7,0.2-1.5,0.7-2c0.5-0.5,1.3-0.8,2-0.8c0.5,0,1,0.2,1.4,0.6C38.8,8.1,39,8.6,39,9.1
			c0,0.5-0.2,1-0.6,1.4C38,10.8,37.5,11,37,11c-0.3,0-0.5,0-0.8-0.1v5.5c0.4-0.9,1-1.7,1.6-2.4c0.5-0.6,0.9-1.2,1.2-1.8h0.4
			c0.3,0.6,0.8,1.3,1.2,1.8c0.6,0.7,1.2,1.5,1.6,2.4V1.9h2L44.4,19L44.4,19z M37,9.8c0.2,0,0.4-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5
			c0-0.4-0.3-0.8-0.7-0.8c0,0,0,0-0.1,0c-0.4,0-0.8,0.4-0.8,0.8l0,0c0,0.2,0.1,0.4,0.2,0.5C36.6,9.7,36.8,9.8,37,9.8z M44,6.4h-1.2
			V2.9H44V6.4z M54.8,19h-2v-7.4c0-0.7-0.2-1.5-0.6-2.1C51.7,9,51,8.8,50.4,8.8c-1.3,0-2.5,0.4-3.5,1.1h-0.1v-2
			c1.2-0.5,2.4-0.7,3.7-0.7c2.9,0,4.4,1.4,4.4,4.3L54.8,19L54.8,19z M67.3,19h-8.9v-3.3c0-0.7,0.4-1.4,1.1-1.7c-0.6-0.3-1-1-1-1.7V9.9
			c0-0.7,0.2-1.5,0.8-2c0.5-0.5,1.3-0.8,2-0.8c0.5,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.9,0.6,1.4c0,0.5-0.2,1-0.6,1.4
			c-0.4,0.4-0.9,0.6-1.4,0.6c-0.3,0-0.5,0-0.8-0.1v1.4c-0.1,0.5,0.3,1,0.8,1.1c0.1,0,0.2,0,0.3,0h0.4v1.3h-0.4c-0.5-0.1-1,0.3-1.1,0.8
			c0,0.1,0,0.2,0,0.3v1.5h5V7.4h2L67.3,19z M61.1,9.8c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.6
			c-0.1-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.8,0.4-0.8,0.8l0,0c0,0.2,0.1,0.4,0.2,0.5C60.7,9.8,60.9,9.8,61.1,9.8z M80.8,19h-9.9v-1.9h1.7
			v-6.2c-0.7,0.3-1.6,0.1-2.2-0.4c-0.8-0.7-0.8-1.9-0.1-2.7c0,0,0,0,0.1-0.1c0.4-0.4,0.9-0.6,1.4-0.6c0.7,0,1.5,0.2,2,0.8
			c0.5,0.5,0.8,1.3,0.7,2v7.3h4.2V7.4h2L80.8,19z M71.9,9.8c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.6
			c-0.1-0.2-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.5C71.5,9.7,71.7,9.8,71.9,9.8z
			M91.3,8.8C90.7,9,90.1,9,89.4,9l-2.3-0.1c-0.6,0-1.1,0.1-1.6,0.3c-0.3,0.2-0.6,0.5-0.6,0.8c0.3-0.1,0.7-0.1,1-0.1
			c1.1-0.1,2.3,0.3,3.1,1c0.7,0.9,1,2,0.9,3.1v2.2c0,0.7-0.2,1.5-0.7,2c-0.5,0.5-1.3,0.8-2,0.7c-0.5,0-1-0.2-1.4-0.6
			c-0.4-0.4-0.6-0.9-0.6-1.4c0-0.5,0.2-1,0.6-1.4c0.4-0.4,0.9-0.6,1.4-0.6c0.3,0,0.5,0,0.8,0.1v-1.2c0.1-0.7-0.1-1.4-0.5-2
			c-0.4-0.4-1-0.7-1.6-0.6c-0.3,0-0.7,0-1,0c-0.4,0-0.7,0.1-1,0.1l-0.8,0.1H83v-0.7c-0.1-1,0.3-2,0.9-2.8c0.9-0.8,2-1.1,3.2-1l2.3,0.1
			c0.3,0,0.7,0,1-0.1L91.3,7h0.1L91.3,8.8L91.3,8.8z M87.2,18c0.2,0,0.4-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.5
			c-0.3-0.3-0.8-0.3-1.1,0c-0.1,0.1-0.2,0.3-0.2,0.5C86.4,17.6,86.7,18,87.2,18C87.2,18,87.2,18,87.2,18z M89.3,6.4h-8.6
			c0.2-0.6,0.4-1.2,0.8-1.7c0.3-0.4,0.6-0.7,1-1c0.7-0.5,1.6-0.8,2.6-0.8c0.9,0,1.8,0.3,2.5,0.8c0.7,0.5,1.3,1.3,1.6,2.1L89.3,6.4z
			M87.6,5.3c-0.2-0.4-0.6-0.7-1-0.9c-1-0.5-2.2-0.5-3.2,0c-0.4,0.2-0.8,0.5-1,0.9H87.6L87.6,5.3z M102.8,19h-2v-6.7
			c0.1-0.9-0.1-1.9-0.7-2.6c-0.6-0.5-1.3-0.8-2.1-0.8c-1.2-0.2-2.4,0.6-2.7,1.8h2v1.4c-1.3,0.5-2,1.7-1.9,3.1V19h-2v-3.5
			c0-1.8,0.6-2.9,1.8-3.3h-2v-0.1c-0.1-1.3,0.4-2.6,1.2-3.6c0.9-0.9,2.2-1.5,3.5-1.4c1.3-0.1,2.6,0.3,3.6,1.2c0.9,1.1,1.3,2.6,1.1,4
			V19z M113.2,19h-2v-7.4c0-0.7-0.2-1.5-0.6-2.1c-0.5-0.5-1.1-0.7-1.8-0.7c-1.3,0-2.5,0.4-3.6,1.1h-0.1v-2c1.2-0.5,2.4-0.7,3.7-0.7
			c2.9,0,4.4,1.4,4.4,4.3L113.2,19L113.2,19z M123.8,8.9c-0.6,0.1-1.3,0.2-1.9,0.2l-2.3,0c-0.6,0-1.1,0.1-1.6,0.3
			c-0.3,0.2-0.6,0.5-0.6,0.8c0.3-0.1,0.7-0.1,1-0.1c1.2-0.1,2.3,0.2,3.2,0.9c0.7,0.9,1,2,0.9,3.2v2.2c0,0.7-0.2,1.5-0.7,2
			c-0.5,0.5-1.3,0.8-2,0.8c-0.5,0-1-0.2-1.4-0.6c-0.8-0.8-0.8-2,0-2.7c0,0,0,0,0,0c0.4-0.4,0.9-0.6,1.4-0.6c0.3,0,0.5,0,0.8,0.1v-1.3
			c0.1-0.7-0.1-1.4-0.5-2c-0.4-0.4-1-0.7-1.6-0.6c-0.3,0-0.7,0-1,0c-0.4,0-0.7,0.1-1,0.1l-0.8,0.1h-0.1v-0.7c-0.1-1,0.3-2,0.9-2.8
			c0.9-0.8,2-1.1,3.2-1l2.3,0.1c0.3,0,0.7,0,1-0.1l0.9-0.2h0.1v1.8L123.8,8.9z M119.6,18c0.2,0,0.4-0.1,0.6-0.2
			c0.1-0.2,0.2-0.4,0.2-0.6c0-0.4-0.4-0.8-0.9-0.8c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.6
			C119.2,17.9,119.4,18,119.6,18z M135.7,19h-2c-1.2-2.3-2.1-3.8-2.4-4.4c-0.3-0.5-0.7-1-1.2-1.4c-0.3-0.3-0.7-0.5-1.2-0.5
			c-0.3,0-0.7,0.1-0.9,0.4c-0.2,0.3-0.4,0.6-0.3,0.9v1.4c0.2-0.1,0.5-0.1,0.8-0.1c0.5,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.9,0.6,1.4
			c0,0.5-0.2,1-0.6,1.4c-0.4,0.4-0.9,0.6-1.4,0.6c-0.7,0-1.5-0.2-2-0.7c-0.5-0.5-0.8-1.3-0.7-2V14c-0.1-0.8,0.2-1.7,0.8-2.3
			c0.6-0.6,1.4-0.8,2.3-0.8c0.7,0,1.3,0.2,1.8,0.6c0.6,0.4,1,0.9,1.4,1.5c0.3,0.5,0.9,1.6,1.8,3.2v-4.4c0-0.8-0.3-1.6-0.8-2.2
			c-0.6-0.6-1.4-0.8-2.2-0.8c-1.5,0-3,0.5-4.2,1.4h-0.1V8c1.4-0.6,2.8-0.9,4.3-0.9c1.4-0.1,2.7,0.4,3.7,1.2c0.9,0.9,1.4,2.2,1.3,3.5
			L135.7,19L135.7,19z M128.4,18c0.2,0,0.4-0.1,0.6-0.2c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.5c-0.3-0.3-0.8-0.3-1.1,0
			c-0.2,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.6C128,17.9,128.2,18,128.4,18z M135.4,22c0.1,0.6-0.4,1.2-1,1.3c0,0,0,0,0,0
			c-0.1,0-0.2,0-0.3,0h-3V22c-0.3,0-0.7-0.1-0.9-0.4c-0.2-0.2-0.4-0.5-0.4-0.9c0-0.7,0.5-1.2,1.1-1.2c0,0,0,0,0,0l0,0
			c0.4,0,0.8,0.1,1,0.4c0.2,0.2,0.4,0.5,0.4,0.9v1.3h1.6c0.2,0,0.4-0.1,0.4-0.3c0,0,0,0,0,0v-2.2h1.1L135.4,22L135.4,22z M131,21.2
			c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.4-0.1-0.5,0l0,0c-0.1,0.1-0.1,0.4,0,0.5l0,0
			C130.9,21.2,131,21.2,131,21.2L131,21.2z M148.5,19h-2v-6.7c0.1-0.9-0.1-1.9-0.7-2.6c-0.6-0.5-1.3-0.8-2.1-0.8
			c-1.2-0.2-2.4,0.6-2.7,1.8h2v1.4c-1.3,0.5-2,1.7-1.9,3.1V19h-2v-3.5c0-1.8,0.6-2.9,1.8-3.3h-2v-0.1c-0.1-1.3,0.4-2.6,1.2-3.6
			c0.9-0.9,2.2-1.5,3.5-1.4c1.3-0.1,2.6,0.3,3.6,1.2c0.9,1.1,1.3,2.6,1.1,4L148.5,19L148.5,19z M161.7,19h-2v-6.7c0-2.2-1-3.4-3.1-3.4
			c-0.9,0-1.7,0.3-2.3,0.9c-0.6,0.6-0.8,1.5-0.8,2.3c0,1,0.2,2,0.5,2.9c0.2-0.9,0.5-1.8,1-2.6c0.4-1,1.6-1.5,2.6-1s1.5,1.6,1,2.6
			c-0.1,0.3-0.3,0.5-0.5,0.7v0.1c-0.4,0.4-0.9,0.6-1.4,0.6c-0.4,0-0.7-0.1-1-0.3c-1,1.1-1.4,2.5-1.3,4h-2v-0.9c0-0.9-0.2-1.8-0.4-2.7
			c-0.3-1.1-0.4-2.2-0.5-3.3c-0.1-1.3,0.4-2.6,1.3-3.6c1-1,2.4-1.5,3.7-1.4c3.4,0,5.1,1.7,5.1,5.2L161.7,19L161.7,19z M156.7,14.1
			c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.5C156.3,14,156.5,14.1,156.7,14.1L156.7,14.1z M161.6,3c-0.9,1.9-2.8,3-5.6,3.4
			l0.5-1.1c-0.3,0-0.6-0.2-0.8-0.4c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.6,0.3-0.9c0.5-0.4,1.2-0.4,1.6,0l0,0
			c0.2,0.2,0.4,0.5,0.4,0.9c0,0.4-0.1,0.9-0.4,1.2c1.2-0.3,2.3-1.2,2.9-2.3H161.6L161.6,3z M156.7,4.4c0.1,0,0.2,0,0.2-0.1
			c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2,0-0.4,0.2-0.4,0.4c0,0.1,0,0.2,0.1,0.2
			C156.5,4.4,156.6,4.4,156.7,4.4L156.7,4.4z M172.1,19h-2v-7.4c0-0.7-0.2-1.5-0.6-2.1c-0.5-0.5-1.1-0.7-1.8-0.7
			c-1.3,0-2.5,0.4-3.6,1.1H164v-2c1.2-0.5,2.4-0.7,3.7-0.7c2.9,0,4.4,1.4,4.4,4.3L172.1,19L172.1,19z M184.9,19h-9.3v-4.5
			c0-0.7,0.2-1.5,0.8-2c0.5-0.5,1.3-0.8,2-0.8c0.5,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.9,0.6,1.4c0,0.5-0.2,1-0.6,1.4
			c-0.4,0.4-0.9,0.6-1.4,0.6c-0.3,0-0.5,0-0.8-0.1v1.7h5.3v-5.4c0-0.8-0.3-1.6-0.8-2.2c-0.6-0.6-1.4-0.8-2.2-0.8c-1.5,0-3,0.5-4.2,1.4
			h-0.1V8c1.4-0.6,2.8-0.9,4.3-0.9c1.4-0.1,2.7,0.4,3.7,1.2c0.9,0.9,1.4,2.2,1.3,3.5L184.9,19L184.9,19z M178.4,14.4
			c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.2-0.3,0.2-0.5c0-0.4-0.4-0.8-0.8-0.8l0,0c-0.4,0-0.8,0.4-0.8,0.8c0,0.2,0.1,0.4,0.2,0.5
			C178,14.4,178.2,14.4,178.4,14.4L178.4,14.4z M197.7,19h-9.3v-4.5c0-0.7,0.2-1.5,0.7-2c0.5-0.5,1.3-0.8,2-0.8c0.5,0,1,0.2,1.4,0.6
			c0.4,0.4,0.6,0.9,0.6,1.4c0,0.5-0.2,1-0.6,1.4c-0.4,0.4-0.9,0.6-1.4,0.6c-0.3,0-0.5,0-0.8-0.1v1.7h5.3v-5.4c0-0.8-0.2-1.6-0.8-2.2
			c-0.6-0.6-1.4-0.8-2.2-0.8c-1.5,0-3,0.5-4.2,1.4h-0.1V8c1.4-0.6,2.8-0.9,4.3-0.9c1.4-0.1,2.7,0.4,3.7,1.2c0.9,0.9,1.4,2.2,1.3,3.5
			L197.7,19L197.7,19z M191.2,14.4c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.2-0.3,0.2-0.5c0-0.4-0.4-0.8-0.8-0.8l0,0c-0.4,0-0.8,0.4-0.8,0.8
			c0,0.2,0.1,0.4,0.2,0.5C190.8,14.4,191,14.4,191.2,14.4z M210.6,15.4c0.7,0.3,1.1,1,1.1,1.8c0,1.1-0.9,2-2,2c-0.5,0-1-0.2-1.4-0.6
			c-0.4-0.4-0.6-0.9-0.6-1.4c0-0.1,0-0.2,0-0.3c-1.1,0.5-2.1,1.2-3,2.1h-1.8v-8.1c-0.2,0.1-0.5,0.1-0.8,0.1c-0.5,0-1-0.2-1.4-0.6
			c-0.4-0.4-0.6-0.9-0.6-1.4c0-0.5,0.2-1,0.6-1.4c0.4-0.4,0.9-0.6,1.4-0.6c0.7,0,1.5,0.2,2,0.8c0.5,0.5,0.8,1.3,0.7,2v7.2
			c1.1-0.9,2.4-1.5,3.8-1.9V7.4h2L210.6,15.4L210.6,15.4z M202.1,9.8c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.2-0.3,0.2-0.5
			c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.8,0.4-0.8,0.8c0,0,0,0,0,0l0,0c0,0.2,0.1,0.4,0.2,0.5
			C201.7,9.8,201.9,9.8,202.1,9.8L202.1,9.8z M209.7,18c0.4,0,0.8-0.4,0.8-0.8v0l0,0c0-0.2-0.1-0.4-0.2-0.5c-0.3-0.3-0.8-0.3-1.1,0
			c-0.2,0.1-0.2,0.3-0.2,0.5C208.9,17.6,209.3,18,209.7,18L209.7,18z M220.2,15.4c0.2-0.1,0.5-0.1,0.8-0.1c0.5,0,1,0.2,1.4,0.6
			c0.8,0.7,0.8,1.9,0.1,2.7c0,0,0,0-0.1,0.1c-0.4,0.4-0.9,0.6-1.4,0.6c-0.7,0-1.5-0.2-2-0.8c-0.5-0.5-0.8-1.3-0.7-2V4.8
			c0-0.8-0.1-1.2-0.4-1.2c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.4-0.2,0.8-0.3,1.2c-0.1,0.8-0.3,1.6-0.8,2.3c-0.2,0.3-0.6,0.5-1,0.6
			c-0.4,0-0.8-0.2-1-0.5c-0.4-0.7-0.6-1.5-0.6-2.2c0-0.4-0.1-0.9-0.3-1.3c-0.1-0.1-0.2-0.2-0.3-0.3H213V1.9h0.3c0.5,0,0.9,0.2,1.2,0.6
			c0.4,0.8,0.6,1.7,0.7,2.6c0,0.4,0.1,0.8,0.3,1.1c0.3-0.3,0.4-0.7,0.4-1.1c0.1-0.9,0.3-1.8,0.8-2.6c0.3-0.4,0.8-0.6,1.3-0.6
			c0.6,0,1.2,0.3,1.6,0.8c0.4,0.6,0.7,1.3,0.6,2.1L220.2,15.4L220.2,15.4z M221,18c0.4,0,0.8-0.4,0.8-0.8c0,0,0,0,0,0
			c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.6
			C220.6,17.9,220.8,18,221,18z M235.2,19h-2c-1.2-2.3-2.1-3.8-2.4-4.4c-0.3-0.5-0.7-1-1.2-1.4c-0.3-0.3-0.7-0.5-1.2-0.5
			c-0.3,0-0.7,0.1-0.9,0.4c-0.2,0.3-0.4,0.6-0.4,0.9v1.4c0.2-0.1,0.5-0.1,0.8-0.1c0.5,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.9,0.6,1.4
			c0,0.5-0.2,1-0.6,1.4c-0.4,0.4-0.9,0.6-1.4,0.6c-0.7,0-1.5-0.2-2-0.7c-0.5-0.5-0.8-1.3-0.8-2V14c-0.1-0.8,0.2-1.7,0.8-2.3
			c0.6-0.6,1.4-0.8,2.3-0.8c0.7,0,1.3,0.2,1.8,0.6c0.5,0.4,1,0.9,1.3,1.5c0.3,0.5,0.9,1.6,1.8,3.2v-4.4c0-0.8-0.3-1.6-0.8-2.2
			c-0.6-0.6-1.4-0.8-2.2-0.8c-1.5,0-3,0.5-4.2,1.4h-0.1V8c1.4-0.6,2.8-0.9,4.3-0.9c1.4-0.1,2.7,0.4,3.7,1.2c0.9,0.9,1.4,2.2,1.3,3.5
			L235.2,19z M227.9,18c0.2,0,0.4-0.1,0.6-0.2c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.5c-0.3-0.3-0.8-0.3-1.1,0
			c-0.2,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.6C227.5,17.9,227.7,18,227.9,18L227.9,18z M248,15.4c0.7,0.3,1.1,1,1.1,1.8
			c0,1.1-0.9,2-2,2c-0.5,0-1-0.2-1.4-0.6c-0.4-0.4-0.6-0.9-0.6-1.4c0-0.1,0-0.2,0-0.3c-1.1,0.5-2.1,1.2-3,2.1h-1.9v-8.1
			c-0.8,0.3-1.6,0.1-2.2-0.4c-0.4-0.4-0.6-0.9-0.6-1.4c0-0.5,0.2-1,0.6-1.4c0.4-0.4,0.9-0.6,1.4-0.6c0.7,0,1.5,0.2,2,0.8
			c0.5,0.5,0.8,1.3,0.7,2v7.2c1.1-0.9,2.4-1.5,3.8-1.9V7.4h2V15.4L248,15.4z M239.5,9.8c0.2,0,0.4-0.1,0.6-0.2
			c0.2-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.8,0.4-0.8,0.8c0,0,0,0,0,0l0,0
			c0,0.2,0.1,0.4,0.2,0.5C239.1,9.7,239.3,9.8,239.5,9.8z M247.1,18c0.4,0,0.8-0.4,0.8-0.8v0l0,0c0-0.2-0.1-0.4-0.2-0.5
			c-0.3-0.3-0.8-0.3-1.1,0c-0.2,0.1-0.2,0.3-0.2,0.5C246.3,17.6,246.7,18,247.1,18L247.1,18z M250,2.5c-0.4,0.7-1.3,1.2-2.7,1.3
			c0.3,0.3,0.5,0.7,0.5,1.2c0,0.9-0.7,1.6-1.6,1.5c-0.4,0-0.8-0.2-1.1-0.5c-0.6-0.6-0.6-1.6-0.1-2.2c0,0,0,0,0.1-0.1
			c0.6-0.4,1.4-0.7,2.2-0.7c0.6,0,1.2-0.2,1.6-0.6L250,2.5z M246.2,5.7c0.2,0,0.3-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5
			c0-0.2-0.1-0.3-0.2-0.5c-0.3-0.3-0.7-0.3-0.9,0c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.3,0.2,0.5C245.9,5.6,246.1,5.7,246.2,5.7
			L246.2,5.7z"/>
	</svg>
  )
}
