import React from "react";

const Logo = ({ src = "", data = {} }) => {
	return (
		<div className="logo-wrapper">
			{src && (
				<a href={data?.mainSiteUrl}>
					<img
						src={src}
						className={"qrp-header-logo"}
						style={{ cursor: data?.mainSiteUrl ? "pointer" : "auto" }}
					/>
				</a>
			)}
		</div>
	);
};

export default Logo;
