import React from "react";
import { show } from '@ebay/nice-modal-react';
import QRCode from "@/popup/QRCode";
import { getAffFromCookie } from "../actions/util";

const Footer = ({data = {}}) => {
	const mainSiteUrl = data?.mainSiteUrl || '';
    const aff = getAffFromCookie();
	return (
		<>
			<footer className="footer">
				<div className="footer-wrapper">
					<ul className="sponsor">
						<li className="item">
							<a href={`${mainSiteUrl}/sponsor/${aff && `?aff=${aff}`}`}>
								<p className="sponsor-title">阿森纳官方合作伙伴</p>
								<img style={{width:'105px'}} className="sponsor-img" src="/static/images/partner-assets/arsenal.webp" alt="阿森纳官方合作伙伴" />
							</a>
						</li>
						<li className="item">
							<a href={`${mainSiteUrl}/sponsor/${aff && `?aff=${aff}`}`} onClick={() => { global.globalGtag('footer', 'click', 'WHU_footer', 'event') }}>
								<p className="sponsor-title">西汉姆联官方主要赞助商</p>
								<img className="sponsor-img" src="/static/images/partner-assets/westham.png" alt="西汉姆联官方主要赞助商" />
							</a>
						</li>
						<li className="item">
							<a href={`${mainSiteUrl}/sponsor/${aff && `?aff=${aff}`}`} onClick={() => { global.globalGtag('footer', 'click', 'BHAFC_footer', 'event') }}>
								<p className="sponsor-title">布萊頓及霍夫<br />官方合作伙伴</p>
								<img className="sponsor-img" src="/static/images/partner-assets/brighton.png" alt="布萊頓及霍夫官方合作伙伴" />
							</a>
						</li>
					</ul>
					<hr />
					<ul className="sponsor">
						<li className="item">
							<a href={`${mainSiteUrl}/sponsor/${aff && `?aff=${aff}`}`} onClick={() => { global.globalGtag('footer', 'click', 'RIO_footer', 'event') }}>
								<p className="sponsor-title">巴西（里约）ATP500 赛事<br />主赞助商</p>
								<img className="sponsor-img" src="/static/images/partner-assets/rio_open.png" alt="巴西（里约）ATP500 赛事主赞助商" />
							</a>
						</li>
					</ul>
					<hr />

					<div className="game-partner">
						<p className="partner-title">游戏平台</p>
						<div className="group">
							<div className="img"><img src="/static/images/partner-assets/asia_gaming.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img src="/static/images/partner-assets/microgaming.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img src="/static/images/partner-assets/mglive.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img src="/static/images/partner-assets/ebet.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img src="/static/images/partner-assets/bull.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img src="/static/images/partner-assets/bti.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img src="/static/images/partner-assets/ky.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img src="/static/images/partner-assets/pragmatic_play.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img src="/static/images/partner-assets/in_sport.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img src="/static/images/partner-assets/betway_p2p.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img src="/static/images/partner-assets/sg_win.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img src="/static/images/partner-assets/bg.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img src="/static/images/partner-assets/tfgaming.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img src="/static/images/partner-assets/joker.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img src="/static/images/partner-assets/evolution.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img src="/static/images/partner-assets/digitain.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img src="/static/images/partner-assets/fulla.png" alt="partner" className="partner-img" /></div>
        </div>
        <div className="group-final">
							<div className="img"><img style={{height:30}} src="/static/images/partner-assets/spribe.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img style={{width:160}} src="/static/images/partner-assets/live_casino_.png" alt="partner" className="partner-img" /></div>
							<div className="img"><img style={{height:30}} src="/static/images/partner-assets/pg.png" alt="partner" className="partner-img" /></div>
						</div>
					</div>

					<hr />

					<div className="partner">
						{/*<div className="item">
							<p className="partner-title">社交媒体</p>
							<div>
								<a onClick={() => {
									show(QRCode, {text:"扫一扫，关注Betway必威微信公众号！最新体育资讯就在您指尖！"}), global.globalGtag('Style10_QRP', 'footer form', 'wechat_footer', 'event')
								}} className="img-footer-link">
									<img src="/static/images/partner-assets/wechat.png" alt="partner" className="partner-img" />
								</a>
							</div>
							</div>*/}
						<div className="item">
							<p className="partner-title">信息安全</p>
							<div>
								<img src="/static/images/partner-assets/iovation.png" alt="partner" className="partner-img" />
							</div>
						</div>
						<div className="item">
							<p className="partner-title">博彩责任</p>
							<div>
								<img src="/static/images/partner-assets/logo_egame.png" alt="partner" className="partner-img" />
								<img src="/static/images/partner-assets/18x.png" alt="partner" className="partner-img" />
							</div>
						</div>
						<div className="item">
							<p className="partner-title">支付平台</p>
							<div>
								<img src="/static/images/partner-assets/wechat_pay.png" alt="partner" className="partner-img" />
								<img src="/static/images/partner-assets/alipay.png" alt="partner" className="partner-img" />
								<img src="/static/images/partner-assets/unionpay.png" alt="partner" className="partner-img" />
								<img src="/static/images/partner-assets/unionpay2.png" alt="partner" className="partner-img" />
								<img src="/static/images/partner-assets/qq.png" alt="partner" className="partner-img" />
								<img src="/static/images/partner-assets/a.png" alt="partner" className="partner-img" />
							</div>
						</div>
						<div className="item">
							<p className="partner-title">支援平台</p>
							<div>
								<img src="/static/images/partner-assets/app_store.png" alt="partner" className="partner-img" />
								<img src="/static/images/partner-assets/android.png" alt="partner" className="partner-img" />
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer;
