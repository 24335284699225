/*
 * @Date: 2023-04-10 12:58:17
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-14 11:07:16
 * @FilePath: \P1-M1-QRP-CODE\utils\hooks\useBanner.js
 */
import { useState, useEffect } from 'react';
import { fetchRequest } from '$CONFIG/Fatch.config';
import HostConfig from '$CONFIG/Host.config'
const CMSURL = HostConfig.Config.CMSURL;

const useBanner = ({ url, fallbackImage = { desktop, mobile } }) => {
    const [bannerList, setBannerList] = useState([]);

    useEffect(() => {
        const getBanner = () => {

            fetchRequest(url, 'GET').then(res => {
                if (res && res.length > 0) {
                    setBannerList(res.map(val => {
                        return { cmsImageUrlWebAlt: val.cmsImageUrlWebAlt , cmsImageUrlMobileAlt : val.cmsImageUrlMobileAlt , cmsImageUrlWeb: CMSURL + val.cmsImageUrlWeb, cmsImageUrlMobile: CMSURL + val.cmsImageUrlMobile }
                    }))
                } else {
                    throw new Error()
                }
            }).catch(error => {
                setBannerList([{
                    cmsImageUrlWeb: fallbackImage.desktop,
                    cmsImageUrlMobile: fallbackImage.mobile
                }])
            })
        }
        getBanner();
        
    }, []);
    return bannerList;
}

export default useBanner