import { useRef } from 'react';
import { useClickAway } from 'ahooks';
import { create, useModal } from '@ebay/nice-modal-react';
import CrossImg from '@/CrossImg';


const QRCode = create(( {text }) => {
    const modal = useModal();
    const ref = useRef(null);
    useClickAway(() => {
        modal.remove();
    }, ref);

    return (
        modal.visible ? (
            <div className="overlay" onClick={(event) => {
                event.preventDefault();
            }}>
                <section ref={ref} className="popup qrcodeBox">
                    <div className="closeBtnBlock">
                        <button type="button" className="closeBtn" onClick={() => { modal.remove(); }}>
                            <CrossImg />
                        </button>
                    </div>

                    <div className="contentBlock">
                        <img src="/static/images/wechat_QR.jpg" className="qrcodeImg" />
                        <p className="qrcode-text">{text}</p>
                    </div>
                </section>
            </div>
        ) : null
    )
})

export default QRCode